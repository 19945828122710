import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Stack } from '@mui/material'
import { Resizable } from 'react-resizable'
import Draggable from 'react-draggable'
import SwipeableTimePicker from '../SwipeableTimePicker/SwipeableTimePicker'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';


import {
  ONE_DAY,
  ONE_DAY_BY_HOUR_AVAILABLE,
  ONE_HOUR,
  FIFTEEN_MINUTES,
  HOURS,
  ELEVEN_PM,
  END_DAY,
  ONE_AM,
  ONE_MINUTE,
} from '../../../variables'

import './TimeRangeSelector.sass'
import { defaultFunction, getFullNameGuest, stopEvent } from '../../utilities/utilities'
import { getExactHour, getTime, formatTimeFromJS, makeHour, decomposeDecimalTime, getCurrentHour, entryTimeIsLaterEqualThanExitTime, getNextRoundedTime, isWithinTimeDifference, getTimeDuration } from '../../utilities/FormatDate'
import MessageBox from '../MessageBox/MessageBox'

document.documentElement.style.setProperty('--ONE_DAY', `${ONE_DAY}px`)
document.documentElement.style.setProperty('--ONE_HOUR', `${ONE_HOUR}px`)
document.documentElement.style.setProperty('--FIFTEEN_MINUTES', `${FIFTEEN_MINUTES}px`)

function Hours() {
  return (
    <Stack
      className='TimeRangeSelector-hours prevent-select'>
      {HOURS.map(hour => <label key={hour}>{hour}</label>)}
    </Stack>
  )
}

function NewEvent(props) {
  const { t } = useTranslation()
  const {
    setOpenTimePicker,
    hasCollision,
    height,
    maxHeight,
    onResize,
    onResizeStart,
    entry_time,
    exit_time
  } = props

  const getMessage = useCallback(() => {
    return (
      <Stack className='TimeRangeSelector-info' onDoubleClick={() => setOpenTimePicker(true)}>
        {
          height >= ONE_HOUR ?
            hasCollision ?
              <label className="subtitle-1">{t('Not available for reservation')}</label> :
              <label className="subtitle-1">{t('Available to reserve')}</label>
            : ''
        }
        <span className='caption-text'>{formatTimeFromJS(entry_time)} - {formatTimeFromJS(exit_time)}</span>
      </Stack>
    )
  }, [entry_time, exit_time, hasCollision, height, setOpenTimePicker, t])

  return (
    <Resizable
      className={`TimeRangeSelector-new-event ${hasCollision ? 'has-collision' : ''}`}
      height={height}
      axis="y"
      resizeHandles={["s"]}
      maxConstraints={[Infinity, maxHeight]}
      onResize={onResize}
      onResizeStart={onResizeStart}
    >
      <div
        style={{ height: height + 'px' }}>
        <div className="handle">
          {getMessage()}
        </div>
      </div>
    </Resizable>
  )
}

const timeToPosition = n => n * ONE_HOUR
const positionToTime = n => makeHour(decomposeDecimalTime(n, ONE_HOUR))

function calculateEventPositions({ entry_time, exit_time }, { fromISO = false } = {}) {
  const top = entry_time ? Math.round(timeToPosition(getExactHour(entry_time, fromISO))) : 0
  const height = exit_time ? Math.round(timeToPosition(getExactHour(exit_time, fromISO)) - top) : 0
  return { top, height, bottom: top + height }
}

function PrevReservations({ reservation }) {
  const { user, top, height } = reservation
  const { t } = useTranslation()

  return (
    <div
      onDoubleClick={stopEvent}
      className='TimeRangeSelector-prev-reservations'
      style={{ top, height }}
    >
      {user && `${getFullNameGuest(user)}  ${getTime(reservation, t)}`}
    </div>
  )
}

function DisabledHours({ time }) {
  const { top, height } = time
  return (
    <div
      onDoubleClick={stopEvent}
      className='TimeRangeSelector-prev-reservations disabled-time'
      style={{ top, height }}
    />
  )
}

const defaultHeight = ONE_HOUR
const defaultLastPositioY = ONE_DAY_BY_HOUR_AVAILABLE - defaultHeight
const defaultPosition = { x: 0, y: 0 }
const defaultBounds = { top: 0, bottom: defaultLastPositioY }
const defaultDisabledHours = { top: 0, height: 0, bottom: 0 }

function TimeRangeSelector(props) {
  const {
    onChange = defaultFunction,
    availableDate = true,
    isToday = false,
    workStartTime = new Date(0, 0, 0, 9, 0), // default 9 AM
    workEndTime = new Date(0, 0, 0, 17, 0), // default 5 PM
    maxReservationTime = 0 
  } = props

  const { t } = useTranslation()
  const [hasCollision, setHasCollision] = useState(false)
  const [exceedsMaxTime, setExceedsMaxTime] = useState(false)
  const [position, setPosition] = useState(defaultPosition)
  const [maxHeight, setMaxHeight] = useState(Infinity)
  const [bounds, setBounds] = useState(defaultBounds)
  const [height, setHeight] = useState(defaultHeight)
  const [disabledHours, setDisabledHours] = useState(defaultDisabledHours)
  const [prevClientY, setPrevClientY] = useState(defaultHeight)
  const [prevHeight, setPrevHeight] = useState(defaultHeight)
  const [openTimePicker, setOpenTimePicker] = useState(false)
  const workStartPosition = timeToPosition(getExactHour(workStartTime, false))
  const workEndPosition = timeToPosition(getExactHour(workEndTime, false))

  const timeRangeSelectorRef = useRef()
  const ref = useRef()

  const getDefaultEntryTime = useCallback(() => {
    const current = getNextRoundedTime(getCurrentHour())
    return (current > ELEVEN_PM) ? ELEVEN_PM : current
  }, [])
  const defaultEntryTime = getDefaultEntryTime()
  const [entry_time, setEntryTime] = useState(defaultEntryTime)
  const [timePickerValue, setTimePickerValue] = useState(defaultEntryTime)

  const getDefaultExitTime = useCallback(() => {
    const current = getNextRoundedTime(getCurrentHour(1))
    return (current > END_DAY || current < ONE_AM) ? END_DAY : current
  }, [])
  const defaultExitTime = getDefaultExitTime()
  const [exitTimePickerValue, setExitTimePickerValue] = useState(defaultExitTime)
  const [exit_time, setExitTime] = useState(defaultExitTime)
  const [reservations, setReservations] = useState([])

  const evalDisabledHours = () => {
    const time = { exit_time: getCurrentHour() }
    const { top, height, bottom } = calculateEventPositions(time, { fromISO: false })
    setDisabledHours({
      top,
      height: height - ONE_MINUTE,
      bottom: bottom - ONE_MINUTE
    })
  }

  const addPosition = useCallback((prevItems = []) => {
    if (!Array.isArray(prevItems)) return []
    return prevItems.map(item => {
      const { top, height } = calculateEventPositions(item, { fromISO: true })
      item.top = top
      item.height = height
      item.bottom = top + height
      return item
    })
  }, [])

  useEffect(() => {
    if (!availableDate) return
    const entry = props.entryTime ? props.entryTime : entry_time
    const exit = props.exitTime ? props.exitTime : exit_time
    setEntryTime(entry)
    setExitTime(exit)
    loadNewPosition({ entry_time: entry, exit_time: exit }, { autoScroll: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isToday && availableDate) evalDisabledHours()
    else setDisabledHours(defaultDisabledHours)
  }, [availableDate, isToday])

  useEffect(() => {
    const prevReservations = addPosition(props.prevReservations)
    setReservations(prevReservations)

    if (!availableDate && prevReservations.length) {
      const top = prevReservations[0].top
      timeRangeSelectorRef.current.scrollTo({ top, left: 0, behavior: 'smooth' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prevReservations])

  useEffect(() => {
    const { y: top } = position
    const bottom = top + height
    evalHasColission({ top, bottom })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations, disabledHours, availableDate])

  useEffect(() => {
    if (!ref.current) {
      ref.current = 1
      return
    }

    onChange({ entry_time, exit_time, hasCollision })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exit_time, hasCollision, exceedsMaxTime, maxReservationTime])

  useEffect(() => {
    if (getTimeDuration(timePickerValue, exitTimePickerValue) > maxReservationTime) {
      setExceedsMaxTime(true)
    } else {
      setExceedsMaxTime(false)
    }
  }, [exitTimePickerValue, maxReservationTime, timePickerValue])


  const evalHasColission = useCallback(({ top, bottom }) => {
    if (!availableDate) return setHasCollision(false)
    const items = [...reservations, disabledHours]

    if (top < workStartPosition || bottom > workEndPosition) {
      setHasCollision(true);
      return;
    }

    const hasCollisions = items.map(r => {
      return (
        (top < r.bottom && bottom > r.top) ||
        (bottom > r.top && top < r.bottom)
      )
    })

    const hasCollision = hasCollisions.some(i => i === true)
    setHasCollision(hasCollision)

  }, [availableDate, reservations, disabledHours, workStartPosition, workEndPosition])

  const loadNewPosition = useCallback((newReservation, options = {}) => {
    const { autoScroll = false } = options
    const { top, height } = calculateEventPositions(newReservation, { fromISO: false })
    const bottom = top + height
    setPosition({ x: 0, y: top })
    setHeight(height)
    setMaxHeight(ONE_DAY_BY_HOUR_AVAILABLE - top)
    evalHasColission({ top, bottom })
    setBounds({ top: 0, bottom: ONE_DAY_BY_HOUR_AVAILABLE - height })
    if (autoScroll) timeRangeSelectorRef.current.scrollTo({ top, left: 0, behavior: "smooth" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onContinueTimePicker = useCallback(() => {
    setOpenTimePicker(false)
    setEntryTime(timePickerValue)
    setExitTime(exitTimePickerValue)
    loadNewPosition({
      entry_time: timePickerValue,
      exit_time: exitTimePickerValue
    }, { autoScroll: true })
  }, [exitTimePickerValue, loadNewPosition, timePickerValue])

  const resetTimePicker = useCallback(() => {
    setTimePickerValue(entry_time)
    setExitTimePickerValue(exit_time)
  }, [entry_time, exit_time])

  function onCloseTimePicker() {
    setOpenTimePicker(false)
    setOpenTimePicker(false)
    resetTimePicker()
  }

  const getClientY = (event) => {
    if (event.clientY !== undefined) {
      return event.clientY;
    }
  
    if (event.touches && event.touches.length > 0) {
      return event.touches[0].clientY;
    }

    if (event.changedTouches && event.changedTouches.length > 0) {
      return event.changedTouches[0].clientY;
    }
    return null;
  };
  
  const onResize = useCallback((event) => {
    const clientY = getClientY(event);
  
    if (clientY === null) {
      console.error('Unable to determine clientY from event:', event);
      return;
    }
  
    let height = prevHeight + (clientY - prevClientY);
  
    if (height < FIFTEEN_MINUTES) {
      height = FIFTEEN_MINUTES;
    } else {
      height = Math.round(height / FIFTEEN_MINUTES) * FIFTEEN_MINUTES;
    }
  
    const { y } = position;
  
    if (height + y >= ONE_DAY_BY_HOUR_AVAILABLE) {
      height = ONE_DAY_BY_HOUR_AVAILABLE - y;
    }
  
    const bottom = height + y;
    const exit_time = positionToTime(bottom);
  
    setHeight(height);
    setExitTime(exit_time);
    setExitTimePickerValue(exit_time);
    evalHasColission({ top: y, bottom });
  }, [prevHeight, prevClientY, position, evalHasColission]);
  
  const onResizeStart = (event, { size }) => {
    const clientY = getClientY(event);
  
    if (clientY === null) {
      console.error('Unable to determine clientY from event:', event);
      return;
    }
  
    setPrevClientY(clientY);
    setPrevHeight(size.height);
  };

  const evalDragPosition = useCallback((_, { y }) => {
    const top = Math.round(y)
    const bottom = top + height
    setPosition({ x: 0, y: top })
    setMaxHeight(ONE_DAY_BY_HOUR_AVAILABLE - top)
    evalHasColission({ top, bottom })
    setBounds({ top: 0, bottom: ONE_DAY_BY_HOUR_AVAILABLE - height })

    const entry_time = positionToTime(top)
    const exit_time = positionToTime(bottom)
    setEntryTime(entry_time)
    setTimePickerValue(entry_time)
    setExitTime(exit_time)
    setExitTimePickerValue(exit_time)
  }, [evalHasColission, height])

  const renderTimePickerMessage = useCallback(() => {
    if (entryTimeIsLaterEqualThanExitTime(timePickerValue, exitTimePickerValue)) {
      return (
        <MessageBox 
          type='error' 
          message={t('Check out time must be greater than check in time')} 
        />
      )
    }
    if (isWithinTimeDifference(timePickerValue, exitTimePickerValue, 15)) {
      return (
        <MessageBox
          type='error'
          message={t('The minimum reservation time is 15 minutes')}
        />
      )
    }
    if (exceedsMaxTime) {
      return (
        <MessageBox
          type='error'
          message={t('Select a maximum time range of [X] minutes', { x: maxReservationTime })}
        />
      )
    }
    return <p className='message'></p>
  }, [exitTimePickerValue, t, timePickerValue, exceedsMaxTime, maxReservationTime])

  const disabledTimePicker = useCallback(() => {
    let isDisabled = false;
    if (entryTimeIsLaterEqualThanExitTime(timePickerValue, exitTimePickerValue) ||
      isWithinTimeDifference(timePickerValue, exitTimePickerValue, 15) || exceedsMaxTime) {
      isDisabled = true;
    }
    return isDisabled;
  }, [exitTimePickerValue, timePickerValue, exceedsMaxTime])

  const renderWorkHoursDisabled = useCallback(() => {
    const top1 = 0;
    const height1 = workStartPosition;

    const top2 = workEndPosition;
    const height2 = ONE_DAY_BY_HOUR_AVAILABLE - workEndPosition;

    return (
      <>
        <DisabledHours time={{ top: top1, height: height1 }} />
        <DisabledHours time={{ top: top2, height: height2 }} />
      </>
    );
  }, [workEndPosition, workStartPosition])

  return (
    <div className="TimeRangeSelector prevent-select" ref={timeRangeSelectorRef}>
      <div className='TimeRangeSelector-hours-wrapper'>
        <Hours />
        {renderWorkHoursDisabled()}
        {isToday && availableDate && <DisabledHours time={disabledHours} />}
        {reservations.map((reservation, index) =>
          <PrevReservations key={index} reservation={reservation} />
        )}
        {availableDate &&
          <Draggable
            axis="y"
            handle=".handle"
            defaultPosition={defaultPosition}
            position={position}
            bounds={bounds}
            grid={[FIFTEEN_MINUTES, FIFTEEN_MINUTES]}
            scale={1}
            onDrag={evalDragPosition}
          >
            <div className="TimeRangeSelector-new-event-wrapper">
              <NewEvent
                setOpenTimePicker={setOpenTimePicker}
                hasCollision={hasCollision}
                onResize={onResize}
                onResizeStart={onResizeStart}
                height={height}
                maxHeight={maxHeight}
                entry_time={entry_time}
                exit_time={exit_time}
              />
            </div>
          </Draggable>
        }
      </div>
      <SwipeableTimePicker
        type="range"
        allTime={false}
        open={openTimePicker}
        setOpen={setOpenTimePicker}
        showAllTime={false}
        time={timePickerValue}
        exitTime={exitTimePickerValue}
        setTime={setTimePickerValue}
        setExitTime={setExitTimePickerValue}
        onContinue={onContinueTimePicker}
        onClose={onCloseTimePicker}
        renderMessage={renderTimePickerMessage}
        isDisabled={disabledTimePicker()}
      />
    </div>
  )
}


TimeRangeSelector.propTypes = {
  prevReservations: PropTypes.array,
  entryTime: PropTypes.instanceOf(Date),
  exitTime: PropTypes.instanceOf(Date),
  workStartTime: PropTypes.instanceOf(Date),
  workEndTime: PropTypes.instanceOf(Date),
};

export default TimeRangeSelector
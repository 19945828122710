import React from 'react'

import {
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material'

import Download from "../Svgs/Download";

import {
  Button,
  Stack
} from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './SuccessBox.sass'

import { downloadTicketAsImage } from '../../utilities/utilities'

function SuccessBox(props) {
  const { 
    children,
    title,
    buttonLabel,
    download
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className='Success-reservation'>
      <div className='success-box'>
        <CheckCircleIcon className="success-icon" />
        <h1 className='success-title'>{title ? title :t('success reservation')}</h1>
        <div id='success-content'>
          {children}
        </div>
        <Stack spacing={2} className='success-footer'>
          {!!download &&
            <Button
              variant="outlined"
              onClick={downloadTicketAsImage} >
              <Download 
                width={'24px'}
              />
              {t('Download')}
            </Button>
          }
          <Button
            variant="outlined"
            onClick={() => navigate("/")} >
            {buttonLabel ? buttonLabel : t('see my reservations')}
          </Button>
        </Stack>
      </div>
    </div>
  )
}

export default SuccessBox